import { db } from '@/firebase/firebaseDB'
const stores = db.collection('stores');



export const getStores = (callback) => {
    return stores.onSnapshot((snapshot) => {
      const list = snapshot.docs.map((doc) => doc.data());
      callback(list);
    });
  };


export const createStore = async (data) => {
  data.id =  stores.doc().id;
  stores.doc(data.id).set(data)
} 
  