<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div v-if="hasPermission">
        <a-breadcrumb class="mb-10">
                <a-breadcrumb-item><router-link to="/">Inicio</router-link></a-breadcrumb-item>
                <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
		<a-row type="flex" :gutter="24">
			<a-col :span="24" :md="12" class="mb-24">	
                <CardCreationStore @createStore="createStore"></CardCreationStore>
			</a-col>
			<a-col :span="24" :md="12" class="mb-24">	
				<CardCreationProduct @createProduct="createProduct"></CardCreationProduct>
			</a-col>
		</a-row>
			<a-card :bordered="false" class="header-solid h-full mb-24" :bodyStyle="{paddingTop: '14px'}">
		<template #title>
			<h6 class="font-semibold">Ordenes</h6>			
			<p>Pedidos por atender</p>	
		</template>
	   <a-table :columns="columns" :data-source="data" :pagination="false">
				<template slot="productName" slot-scope="productName">
					<div class="author-info">
						<h6 class="m-0">{{ productName }}</h6>		
					</div>
				</template>
				<template slot="dateCreated" slot-scope="dateCreated">
					<div class="author-info">
						<h6 class="m-0">{{ dateCreated| date  }}</h6>	
					</div>
				</template>
				<template slot="status" slot-scope="status">
					<a-tag class="tag-status" :class="getColorbyStatus(status)">
						{{ status}}
					</a-tag>
				</template>
				<template slot="editBtn" slot-scope="row">
				<router-link :to="`/orders-admin/${row.id}`" class="btn-sign-in">	
					<a-button type="link" :data-id="row.key" class="btn-edit">
						VER
					</a-button>
					</router-link>	
				</template>
			
		</a-table>
	</a-card>
	</div>
	<div v-else>
	<a-result status="401" title="401" sub-title="No tienes permiso para acceder a esta sección">
		<template #extra>
			<router-link class="ant-btn ant-btn-primary" to="/">Inicio</router-link>
		</template>
	</a-result>
	</div>
</template>

<script>

	import CardCreationProduct from "@/components/Cards/CardCreationProduct"
	import CardCreationStore from "@/components/Cards/CardCreationStore"
	import CardOrdersResume from "@/components/Cards/CardOrdersResume"
	import CardProject from "@/components/Cards/CardProject"
	import  { createStore }  from '@/services/stores/stores';
	import  { createProduct } from '@/services/products/products';
	import { getOrders } from '@/services/orders/orders';
	import  config from '@/utils/table.config'
	import { OrderStatusClient } from '@/utils/orderStatus'
	import { isLogin} from '@/services/auth/auth';
    import { getPermissionByCategoryEmail } from '@/services/auth/permissions';
	
	const columns = config.ordersColumns;
	// Conversation's list data.


	// Project cards data


	export default ({
		components: {
			CardCreationProduct,
			CardCreationStore,
			CardOrdersResume,
			CardProject,
		},
		data() {
			return {
				// Active button for the "User Profile" card's radio button group.
				profileHeaderBtns: 'overview',

				// Associating Conversation's list data with its corresponding property.
				data:null,
				columns,
				hasPermission:false

			}
		},
		created(){
			this.getOrders()
		},
		methods:{
			async getOrders(){
				isLogin((user)=>{
				if(user){
					getPermissionByCategoryEmail((data)=>{
					this.hasPermission=data
				
			},user.email)
				}
		
				
			})
				await getOrders((data)=>{
					this.data=data
				})
			},
			async createStore(val){
			    await createStore(val)
			},
			getColorbyStatus(status){
				switch (status) {
				case OrderStatusClient.ENTREGADO:
					return 'status-recived'
					break;
				case OrderStatusClient.DISENO:
					return 'status-design'
					break;
				case OrderStatusClient.TRABAJANDO:
					return 'status-working'
					break;
				case OrderStatusClient.FINALIZADO:
					return 'status-end'
					break;			
				}
			},
			async createProduct(val){
				
				let dataTosave={
					available:true,
					category:val.category,
					description:val.description,
					images:[val.image1,val.image2,val.image3,val.image4],
					name:val.name,
					price:val.price,
					storeInfo:JSON.parse(val.store)

				}
				await createProduct(dataTosave)
			}
		}
	})

</script>

<style lang="scss">
</style>