<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>


	
		
		<!-- Sign Up Form -->
		<a-card :bordered="false">
			<template #title>
				<h6 class="font-semibold m-0">Crear Tienda</h6>
			</template>
			
			
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'name',
						{ rules: [{ required: true, message: 'Información requerida' }] },
						]"
						placeholder="Nombre"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'numberPhone',
						{ rules: [{ required: true, message: 'Información requerida' }] },
						]"
						placeholder="Número teléfonico de tienda"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'profilePicture',
						{ rules: [{ required: true, message: 'Información requerida' }] },
						]"
						type="text"
						placeholder="Portada"
					>
					</a-input>
				</a-form-item>
                <a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'address',
						{ rules: [{ required: true, message: 'Información requerida' }] },
						]"
                        type="text"
						placeholder="Direccion"
					>
					</a-input>
				</a-form-item>
                <a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'description',
						{ rules: [{ required: true, message: 'Información requerida' }] },
						]"
						type="text"
						placeholder="Descripción"
					>
					</a-input>
				</a-form-item>
			
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						CREAR
					</a-button>
				</a-form-item>
			</a-form>
			
		</a-card>
		<!-- / Sign Up Form -->


</template>

<script>

	export default ({
		data() {
			return {
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.$emit('createStore', values)
						this.form.resetFields()
					}
				});
			},
		},
	})

</script>

<style lang="scss">
</style>