export const OrderStatusClient=
    {
        ENTREGADO: 'ENTREGADO',
        DISENO: 'DISENO',
        TRABAJANDO:'TRABAJANDO',
        FINALIZADO:'FINALIZADO'
    };
export  const OrderStatusAdmin=
    {
        PENDIENTE: 'ENTREGADO',
        DISENO: 'DISENO',
        TRABAJANDO:'TRABAJANDO',
        FINALIZADO:'FINALIZADO'
    };
        
 
 