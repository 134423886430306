<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>


	
		
		<!-- Sign Up Form -->
		<a-card :bordered="false">
			<template #title>
				<h6 class="font-semibold m0">Crear producto</h6>
			</template>
    
			
			
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
			
					<a-row>
				     <a-col :md="12">
					 	<a-form-item class="mb-10">
                        	<a-input style="width: 90%" v-decorator="[ 'name',{ rules: [{ required: true, message: 'Información requerida.' }] },]"placeholder="Nombre"></a-input>
						</a-form-item>
                    </a-col>
					 <a-col :md="12">
					  	<a-form-item class="mb-10">
                      		<a-input  style="width: 90%"v-decorator="[ 'price',{ rules: [{ required: true, message: 'Información requerida.' }] },]" type="number" placeholder="Precio"></a-input>
						</a-form-item>
                    </a-col>
                </a-row>  
				
				 
				 <a-row>
				     <a-col :md="12">
					  <a-form-item class="mb-10">
                           <a-select v-decorator="['category',{ rules: [{ required: true, message: 'Información requerida.' }] },]"  placeholder="Categoria" style="width: 90%" class="mb-10 mt-10" >
								<a-select-option  v-for="(item, index) in category" :key="item.key" :value="item.key" >
									{{item.value}}
								</a-select-option>
							</a-select> 
						</a-form-item>
                    </a-col>
					 <a-col :md="12">
					 	  <a-form-item class="mb-10">
								<a-select v-decorator="['store',{ rules: [{ required: true, message: 'Información requerida.' }] },]"  placeholder="Tienda" style="width: 90%" class="mb-10 mt-10" >
									<a-select-option  v-for="(item, index) in stores" :key="item.id" :value="JSON.stringify(item)">
										{{item.name}}
									</a-select-option>	
								</a-select>
							</a-form-item>
                    </a-col>
                </a-row>    
					
		
				 
				<a-form-item class="mb-5">
					<a-input style="width: 95%" v-decorator="['description',{ rules: [{ required: true, message: 'Información requerida.' }] },]"type="text"placeholder="Descripción"></a-input>
				</a-form-item>
              
            
               
		
				 <a-row>
				     <a-col :md="12">
					 	<a-form-item class="mb-10">
                        <a-input
						  style="width: 90%"
							v-decorator="[
							'image1',
							{ rules: [{ required: true, message: 'Información requerida' }] },
							]"
							type="text"
							placeholder="Primera imagen"
						>
					</a-input>
						</a-form-item>
                    </a-col>
					 <a-col :md="12">
					 	<a-form-item class="mb-10">
                        <a-input
						style="width: 90%"
							v-decorator="[
							'image2',
							{ rules: [{ required: true, message: 'Información requerida' }] },
							]"
							type="text"
							placeholder="Segunda imagen"
					    >
					    </a-input>
							</a-form-item>
                    </a-col>
                </a-row>  
				
				 <a-row>
				     <a-col :md="12">
					 	<a-form-item class="mb-10">
                        <a-input
						   style="width: 90%"
							v-decorator="[
							'image3',
							{ rules: [{ required: true, message: 'Información requerida' }] },
							]"
							type="text"
							placeholder="Tercera imagen "
						>
					</a-input>
						</a-form-item>
                    </a-col>
					 <a-col :md="12">
					 	<a-form-item class="mb-10">
                        <a-input
						 style="width: 90%"
							v-decorator="[
							'image4',
							{ rules: [{ required: true, message: 'Información requerida' }] },
							]"
							type="text"
							placeholder="Cuarta imagen"
					    >
					    </a-input>
							</a-form-item>
                    </a-col>
                </a-row>   
					
			
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						CREAR
					</a-button>
				</a-form-item>
			</a-form>
			
		</a-card>
		<!-- / Sign Up Form -->


</template>

<script>
import { getCategory } from '@/services/category/category';
import { getStores } from '@/services/stores/stores';
	export default ({
		data() {
			return {
				category:[],
				stores:[]
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created(){
			this.getCategory()
			this.getStores()
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
					this.$emit('createProduct', values)
					this.form.resetFields()
					
					}
				});
			},
			async getStores(){
				
					let list =  await getStores((list) => {
					this.stores=list
				})	
			},
			async getCategory(){
				let list =  await getCategory((list) => {
					this.category=list
					
	    		})	
			}
		},
	})

</script>

<style lang="scss">
</style>